(function(){

$.fn.extend({
    validate:function(){
      var valid=true;
      this.find('.req').each(function(i,v){
        if(!$(v).val().trim().length){
          $(v).on('keyup change',$(v).checkValid)
                .closest('.form-group')
                  .addClass('input-error')
                    .prepend(function(){
                      if(!$(v).closest('.form-group').has('.err-msg').length){
                        return '<span class="err-msg">'+$(v).data('err-msg')+'</span>';
                      }
                    });
          valid=false;
        }
      });
      return valid;
    },
    checkValid:function(){
      var t=$(this);
      var container=t.closest('.form-group');
      t.val().trim().length&&this.checkValidity()?container.removeClass('input-error'):container.addClass('input-error');
    }
  });



$('#form').on('submit',function(e){

    e.preventDefault();
    var form=$(this);
    if(form.validate()){
      
      var button=form.find('#form-submit')
                  .addClass('mail-resp-await').prop('disabled',true).val(function(){
                    return $(this).data('wait');
                  });
      var inputIme=form.find('#name').prop('disabled',true);
      var inputEmail=form.find('#email').prop('disabled',true);
      var select=form.find('#select-dest').prop('disabled',true);
      var inputPredmet=form.find('#predmet-poruke').prop('disabled',true);
      var inputMsg=form.find('#msg').prop('disabled',true);
      
      var testUrl='http://localhost:80/median-api/send-email/send-email.php'
      var url='';

       $.ajax('/api/send-email/send-email.php',{
          type: "POST",
          dataType:'json',
          data: {
              ime: inputIme.val().trim(),
              email: inputEmail.val().trim(),
              poruka: inputMsg.val().trim(),
              primalac: select.val(),
              predmet: inputPredmet.val()
          }
        }).done(function(resp){
          if(resp.sent){
            button.toggleClass('mail-resp-await mail-resp-succ').val(button.data('success'));
          }
          else{
            //sta cemo za error
            inputIme.prop('disabled',false);
            inputEmail.prop('disabled',false);
            inputMsg.prop('disabled',false);
            button.removeClass('mail-resp-await').val(button.data('default'));
          }
          
        }).fail(function(jqXHR,txt,err){
          //sta cemo za error
        });


    }//End if validate
  });




})();