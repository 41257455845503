$(window).on('load',function(){
	$('#home-hero .hero-text').addClass('background-loaded');
});


$(document).ready(function($){

	//open/close primary navigation
	$('.cd-primary-nav-trigger').on('click', function(e){
		e.preventDefault();
		$('.cd-menu-icon').toggleClass('is-clicked'); 
		$('.cd-header').toggleClass('menu-is-open');
		
		//in firefox transitions break when parent overflow is changed, so we need to wait for the end of the trasition to give the body an overflow hidden
		if( $('.cd-primary-nav').hasClass('is-visible') ) {
			$('.cd-primary-nav').removeClass('is-visible').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend',function(){
				$('body').removeClass('overflow-hidden');
			});
		} else {
			$('.cd-primary-nav').addClass('is-visible').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend',function(){
				$('body').addClass('overflow-hidden');
			});	
		}
	});

	//Footer contact copyright
	$('#copy-year').text(new Date().getFullYear());
	//Blur link on click
	$('.procitaj-biografiju').on('click',function(e){
		e.preventDefault();
		if($(this).hasClass('bio-opened')){
			$(this).text('Pročitaj biografiju');
		}
		else{
			$(this).text('Zatvori biografiju');
		}
		$(this).toggleClass('bio-opened');
		this.blur();
	})

	//scroll Top
	$('#toTop').on('click',function(e){
		e.preventDefault();
		$('html, body').animate({
        	scrollTop: 0
    	}, 800);
    	$(this).blur();
	});
	


});